<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>购买订单</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item">
              <label>藏品名称：</label>
              <el-input
                placeholder="请输入藏品名称"
                v-model="query.NFTName"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>订单ID：</label>
              <el-input
                placeholder="请输入订单ID"
                v-model="query.orderId"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>买家用户：</label>
              <el-input
                placeholder="请输入买家用户手机号"
                v-model="query.phoneNumber"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>下单时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>订单状态：</label>
              <el-select placeholder="请选择订单状态" v-model="query.status">
                <el-option label="未付款" value="3"></el-option>
                <el-option label="已完成" value="4"></el-option>
                <el-option label="已取消" value="5"></el-option>
                <el-option label="失败" value="6"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn mt25"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button
            icon="el-icon-document"
            type="success"
            plain
            size="small"
            @click="doExport"
            >导出</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="orderId" label="订单id"> </el-table-column>
            <el-table-column prop="NFTName" label="藏品名称" align="center">
            </el-table-column>
            <el-table-column
              prop="NFTPrice"
              label="藏品价格（元）"
              align="center"
            ></el-table-column>
            <el-table-column prop="count" label="购买数量" align="center">
            </el-table-column>
            <el-table-column
              prop="orderPrice"
              label="订单金额（元）"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="phoneNumber"
              label="买家手机号"
              align="center"
            ></el-table-column>
            <el-table-column prop="status" label="订单状态" align="center">
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.status == 3"
                  >未付款</span
                >

                <span class="font-success" v-else-if="scope.row.status == 4"
                  >已完成</span
                >

                <span class="font-main" v-else-if="scope.row.status == 5"
                  >已取消</span
                >

                <span class="font-danger" v-else-if="scope.row.status == 6"
                  >失败</span
                >
              </template>
            </el-table-column>

            <el-table-column prop="createdAt" label="创建时间">
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goDetail(scope.row)"
                    >查看订单详情</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderQuery } from "@/api/module/order";
import { postExport } from "@/api/config";

export default {
  name: "OrderIndex",
  data() {
    return {
      showLoading: false,
      dialogshow: false,
      query: {
        NFTName: "",
        orderId: "",
        phoneNumber: "",
        timeArr: [],
        status: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    async doExport() {
      let odata = this.setQueryParams();
      const data = await postExport("v1/order/buy/list/download", odata);
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", "excel.xls");
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      OrderQuery(query).then((r) => {
        if (r.code != 0) return;
        console.log(r);
        this.total = r.data.total;
        this.tableData = r.data.list;
      });
    },
    // 查看
    goDetail(row) {
      this.$router.push(`/order/detail/${row.orderId}`);
    },
    // 设置筛选
    setQueryParams() {
      let query = {
        pagination: true,
        page: this.query.pageIndex,
        pageSize: this.query.pageSize,
      };
      if (this.query.NFTName) {
        query.NFTName = this.query.NFTName;
      }
      if (this.query.orderId) {
        query.orderId = this.query.orderId;
      }
      if (this.query.phoneNumber) {
        query.phoneNumber = this.query.phoneNumber;
      }
      if (this.query.status) {
        query.status = this.query.status;
      }
      if (this.query.timeArr.length) {
        query.startTime = this.query.timeArr[0];
        query.endTime = this.query.timeArr[1];
      }

      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        NFTName: "",
        orderId: "",
        phoneNumber: "",
        timeArr: [],
        status: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



